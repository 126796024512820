import React from 'react';
import PropTypes from 'prop-types';

import styles from './video.module.scss';

const Video = ({
	src,
	loop,
	muted,
	poster,
	preload,
	controls,
	autoplay,
	playsinline
}) => {
	const type = src.match(/\.mp4$/) ? 'video/mp4' : 'video/webm';

	return (
		<div role="presentation" className={styles.wrapper}>
			<video
				loop={loop}
				muted={muted}
				poster={poster}
				preload={preload}
				autoPlay={autoplay}
				controls={controls}
				playsInline={playsinline}
				className={styles.video}>
				<source src={src} type={type} />
			</video>
		</div>
	);
};

Video.defaultProps = {
	autoplay: null, // needs to be removed from DOM!
	controls: null, // needs to be removed from DOM!
	playsinline: true,
	preload: 'auto',
	muted: true,
	loop: true,
	src: null
};

Video.propTypes = {
	loop: PropTypes.bool,
	muted: PropTypes.bool,
	controls: PropTypes.bool,
	autoplay: PropTypes.bool,
	poster: PropTypes.string,
	preload: PropTypes.string,
	playsinline: PropTypes.bool,
	src: PropTypes.string.isRequired
};

export default Video;
