import React from 'react';
import PropTypes from 'prop-types';

import IconSlider from './icon-slider';

import usePartners from '../../hooks/use-partners';
import useBlockPartners from '../../hooks/use-block-partners';

// import styles from './partners.module.scss';

const Partners = ({ slidesToShow }) => {
	const { title } = useBlockPartners();
	const partners = usePartners();

	return <IconSlider title={title} items={partners} slidesToShow={slidesToShow} />;
};

Partners.defaultProps = {
	slidesToShow: 2
};

Partners.propTypes = {
	slidesToShow: PropTypes.number
};

export default Partners;
