import { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import LocaleContext from '../contexts/locale';

const all = [];
const uuidMap = {};
const localeMap = {};

export const usePartnerByUuid = (uuid) => {
	invokeQueryAndMapData();

	if (uuid in uuidMap) {
		return uuidMap[uuid];
	}

	return null;
};

const invokeQueryAndMapData = () => {
	const {
		allMarkdownRemark: {
			edges: partners
		}
	} = useStaticQuery(query);

	const {
		currentLocale
	} = useContext(LocaleContext);

	const mapItem = ({
		node: {
			fields,
			frontmatter: data
		}
	}) => ({
		...data,
		...fields
	});

	if (all.length === 0) {
		for (let partner of partners) {
			const item = mapItem(partner);
			const { uuid, locale } = item;
			
			localeMap[locale] = localeMap[locale] || [];
			localeMap[locale].push(item);
			uuidMap[uuid] = item;
			all.push(item);
		}
	}

	return localeMap[currentLocale] || [];
};

export default invokeQueryAndMapData;

const query = graphql`
	query usePartners {
		allMarkdownRemark(
	    	filter: {
		        frontmatter: {
		          type: {
		            eq: "partner"
		          }
		        }
		  	}
	  	)
	  	{
			edges {
				node {
					frontmatter {
						locale
						uuid
						name
						icon {
							publicURL
						}

					}
				}
			}
		}
	}
`;